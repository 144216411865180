<template>
  <div>
    <base-header type="gradient-warning" class="pb-6 pb-8 pt-5 pt-md-8" />
    <b-container class="mt--7" fluid>
      <b-row class="justify-content-center">
        <b-col md="6">
          <card>
            <VeeObserver ref="form" slim>
              <VeeProvider name="image" rules="required|length:1" v-slot="{ errors }">
                <b-form-group label="Image(Trademark)*">
                  <FileUpload v-model="payload.image" ref="image" />
                  <small class="text-danger" v-if="errors.length">
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </VeeProvider>
              <base-input
                class="input-group-alternative"
                placeholder="Title"
                v-model="payload.title"
              />
              <b-textarea
                class="input-group-alternative mb-4"
                placeholder="Description"
                v-model="payload.description"
              />
              <div class="bg-light p-4 rounded">
                <base-input
                  class="input-group-alternative"
                  placeholder="Button Title (Ex: Shop now)"
                  v-model="payload.buttonTitle"
                />
                <VeeProvider name="url" rules="url" v-slot="{ errors }">
                  <base-input
                    class="input-group-alternative"
                    placeholder="Url (Ex: https://amazoniagrass.com)"
                    v-model="payload.url"
                    :error="errors[0]"
                  />
                </VeeProvider>
              </div>
              <div class="text-center">
                <base-button
                  variant="primary"
                  type="submit"
                  class="my-4"
                  @click="isEdit ? update() : create()"
                  :loading="loading"
                >
                  <i class="fa fa-save"></i> {{ isEdit ? "Update" : "Create" }}
                </base-button>
              </div>
            </VeeObserver>
          </card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import BannersService from "features/banners/services/BannersService";
import { resource } from "helpers";

export default {
  name: "FormBanners",
  data: () => ({
    loading: false,
    payload: {
      title: "",
      image: [],
      description: "",
      url: "",
      buttonTitle: "",
    },
    selectBannersSection: [],
  }),
  computed: {
    isEdit() {
      return this.$route.name === "banners-edit";
    },
  },
  mounted() {
    if (this.isEdit) this.getData();
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const { data } = await BannersService.show(this.$route.params.id);
        this.payload.title = data.title;
        this.payload.description = data.description;
        this.payload.buttonTitle = data.button_title;
        this.payload.url = data.url;
        this.payload.image = [resource(data.image)];
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Failed to get registration data",
        });
      } finally {
        this.loading = false;
      }
    },
    async create() {
      try {
        if (await this.$refs.form.validate()) {
          this.loading = true;
          const { title, image, description, url, buttonTitle } = this.payload;
          await BannersService.create({
            title,
            description,
            url,
            banner_section_id: this.$route.params.section_id,
            button_title: buttonTitle,
            image: {
              type: image[0].ext,
              value: image[0].item,
            },
          });
          this.$swal({
            icon: "success",
            title: "Registration performed successfully",
          }).then(() => this.$router.push("/banners"));
        }
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Trademark registration failed",
          text: "Check the data entered and try again",
        });
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        if (await this.$refs.form.validate()) {
          this.loading = true;
          const { title, image, description, url, buttonTitle } = this.payload;
          const payload = {
            title,
            description,
            url,
            button_title: buttonTitle,
          };
          if (image[0].constructor.name === "Object") {
            payload.image = {
              type: image[0].ext,
              value: image[0].item,
            };
          }
          await BannersService.update(payload, this.$route.params.id);
          this.$swal({
            icon: "success",
            title: "Record updated successfully",
          }).then(() => this.$router.push("/banners"));
        }
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Update failed",
          text: "Verify the given informations and try again",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
